<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import PeoplesStore from "@/modules/peoples/peoples-store";
import PeoplesPaginate from "@/components/paginate.vue";
import Swal from "sweetalert2";
import PeoplesDelete from "@/modules/peoples/components/peoples-delete.vue";
import PeoplesRemove from "@/modules/peoples/components/peoples-remove.vue";
import PeoplesShow from "@/modules/peoples/components/peoples-show.vue";
import PeoplesRacesStore from "@/modules/peoples-races/peoples-races-store";
import PeoplesTypesStore from "@/modules/peoples-types/peoples-types-store";
import {del} from "vue";

export default {
  name: "Cadastros",
  data() {
    return {
      title: "Cadastros",
      single: "Cadastro",
      items: [
        {text: "Home", to: {name: "home"}},
        {text: "Cadastros", active: true},
      ],
      table: [
        {label: "ID", key: "id"},
        {label: "Nome", key: "name"},
        {label: "Documento", key: "document"},
        {label: "Foto", key: "photo"},
        {label: "Criação", key: "created_at"},
        {label: "Atualização", key: "updated_at"},
        {label: "...", key: "btn"},
      ],
      list: [],
      showNewForm: false,
      loadingForm: false,
      paginate: {
        total_pages: 1,
        page: 1,
        perPage: 10,
        total: 0,
      },
      loadingPage: false,
      buscar: "",
      form: {
        id: null,
        id_people_type: null,
        id_people_race: null,
        sex: null,
        name: null,
        document: null,
        photo: null,
        date_of_birth: null,
      },
      optionsPeopleTypes: [{text: "Selecione", value: null}],
      optionsPeopleRaces: [{text: "Selecione", value: null}],
      optionsSex: [
        {text: "Masculino", value: "M"},
        {text: "Feminino", value: "F"},
        {text: "Juridica", value: "PJ"},
      ]
    }
  },
  components: {PeoplesShow, PeoplesRemove, PeoplesDelete, PeoplesPaginate, Layout, PageHeader},
  mounted() {
    this.getPaginate(this.paginate.page);
    this.loadOptionsPeoplesTypes();
    this.loadOptionsPeoplesRaces();
  },
  watch: {
    'form.name': function (newValue) {
      this.form.identifier = this.$slug_format(newValue);
    }
  }, methods: {
    handlerUpload(event){
      this.form.photo = event.target.files[0];
      console.log(this.form.photo);
    },
    loadOptionsPeoplesTypes() {
      PeoplesTypesStore.all().then((response) => {
        this.optionsPeopleTypes.push(...response.data.map((item) => {
          return {text: item.label, value: item.id}
        }));
      }).catch((error) => {
        console.log(error);
      });
    },
    loadOptionsPeoplesRaces() {
      PeoplesRacesStore.all().then((response) => {
        this.optionsPeopleRaces.push(...response.data.map((item) => {
          return {text: item.label, value: item.id}
        }));
      }).catch((error) => {
        console.log(error);
      });
    },
    getPaginate(e = 1) {
      this.paginate.page = e;
      this.loadingPage = true;
      PeoplesStore.paginate({
        page: this.paginate.page,
        limit: this.paginate.perPage,
        search: this.buscar,
      }).then((response) => {
        const {data, limit, page, total, total_pages} = response.data;
        this.list = data;
        this.paginate.total = total;
        this.paginate.perPage = limit;
        this.paginate.page = page;
        this.paginate.total_pages = total_pages;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    cleanForm() {
      this.form = {
        id: null,
        id_people_type: null,
        id_people_race: null,
        sex: null,
        name: null,
        document: null,
        photo: null,
        date_of_birth: null,
      }
    },
    edit(item) {
      this.form = JSON.parse(JSON.stringify(item));
      this.form.photo_show = this.form.photo;
      delete this.form.photo;
      this.form.date_of_birth = this.form.date_of_birth.split("T")[0];
      this.showNewForm = true;
    },
    save(event) {
      event.preventDefault();
      this.loadingForm = true;
      delete this.form.photo_show;

      const formData = new FormData();
      if (this.form.id) {
        formData.append("id", this.form.id);
      }

      formData.append("id_people_type", this.form.id_people_type);
      formData.append("id_people_race", this.form.id_people_race);
      formData.append("sex", this.form.sex);
      formData.append("name", this.form.name);
      formData.append("document", this.form.document);
      formData.append("date_of_birth", this.form.date_of_birth);

      if (this.form.photo) {
        formData.append("photo", this.form.photo);
      }


      if (this.form.id) {
        PeoplesStore.update(this.form.id,formData).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Cadastro atualizado com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar o Cadastro",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        PeoplesStore.create(formData).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Cadastro criado com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar o Cadastro",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>

              <div class="row mb-4 align-items-center">
                <div class="col-md-5">
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <input
                          v-model="buscar"
                          type="text"
                          placeholder="Pesquisar...."
                          class="form-control"
                          @change="getPaginate(1)"
                      />
                      <span class="d-block d-md-none"></span>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="getPaginate(1)" type="button" class="btn btn-light btn-block">
                        <i class="fa fa-search"></i> Buscar
                      </button>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="buscar=null; getPaginate(1) " class="btn btn-light btn-block">
                        <i class="fa fa-broom"></i> Limpar
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-7 mb-2 text-right align-items-center">
                  <span class="d-block d-md-none p-2"> </span>
                  <div>
                    <button class="btn btn-primary" @click="showNewForm = true">
                      <i class="fa fa-plus"></i>
                      <span v-if="form.id">Editando</span>
                      <span v-if="!form.id">Novo</span>
                    </button>
                  </div>
                  <div v-show="showNewForm">
                    <div class="box-form text-left">
                      <div id="form-novo-edit">
                        <i @click="showNewForm = false; cleanForm();" class="fa float-right fa-2x fa-eye-slash"></i>
                        <h1 class="mt-5 mb-5" v-if="!form.id">Novo {{ single }}</h1>
                        <h1 class="mt-5 mb-5" v-if="form.id">Editando {{ single }}</h1>
                        <div>
                          <b-overlay :show="loadingForm">
                            <b-form @submit="save" class="was-validated">

<!--                              id_people_type-->
                              <b-form-group label="Tipo de pessoa" description="Selecione o tipo de pessoa" >
                                <b-form-select
                                    id="id_people_type"
                                    v-model="form.id_people_type"
                                    :options="optionsPeopleTypes"
                                    required
                                ></b-form-select>
                              </b-form-group>
<!--                              id_people_race-->
                              <b-form-group label="Raça pessoa" description="Selecione raça" >
                                <b-form-select
                                    id="id_people_race"
                                    v-model="form.id_people_race"
                                    :options="optionsPeopleRaces"
                                    required
                                ></b-form-select>
                              </b-form-group>
<!--                              sex-->
                              <b-form-group label="Sexo" description="Selecione o sexo" >
                                <b-form-select
                                    v-model="form.sex"
                                    :options="optionsSex"
                                    required
                                ></b-form-select>
                              </b-form-group>
<!--                              name-->
                              <b-form-group label="Nome" label-for="name" description="Nome da pessoa">
                                <b-form-input id="name" v-model="form.name" required></b-form-input>
                              </b-form-group>
<!--                              document-->
                              <b-form-group label="Documento" label-for="document" description="Documento da pessoa">
                                <b-form-input id="document" v-model="form.document" required></b-form-input>
                              </b-form-group>
<!--                              photo-->
                              <b-form-group label="Foto" label-for="photo" description="Foto da pessoa">
                                <b-form-file @change="handlerUpload" id="photo" v-model="form.photo" ></b-form-file>
                                <img :src="form.photo_show" alt="">
                              </b-form-group>
<!--                              date_of_birth-->
                              <b-form-group label="Data de nascimento" label-for="date_of_birth" description="Data de nascimento da pessoa">
                                <b-form-input type="date" id="date_of_birth" v-model="form.date_of_birth" required></b-form-input>
                              </b-form-group>


                              <b-button type="submit" variant="primary">
                                <span v-if="form.id">Salvar alterações</span>
                                <span v-if="!form.id">Cadastrar novo</span>
                              </b-button>
                            </b-form>
                          </b-overlay>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <peoples-paginate :paginate="paginate" @change-page="getPaginate"></peoples-paginate>
               <b-table striped hover :items="list" :fields="table" class="  table-responsive-sm table-responsive-md">
                <template v-slot:cell(updated_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(photo)="data">
                  <img v-if="data.item.photo" :src="data.item.photo" alt="" width="50" class="img-thumbnail">
                  <i v-if="!data.item.photo" class="fa fa-photo-video"></i>
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(btn)="data">
                  <button @click="edit(data.item)" class="btn pl-4 pr-4 btn-sm btn-info"><i class="fa fa-edit"></i> Editar</button>
                  <peoples-delete :value="data.item" @change="getPaginate(1)"></peoples-delete>
<!--                  <peoples-remove :value="data.item" @change="getPaginate(1)"></peoples-remove>-->
                  <peoples-show :value="data.item"></peoples-show>
                </template>
              </b-table>
              <peoples-paginate :paginate="paginate" @change-page="getPaginate"></peoples-paginate>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped>

</style>

